<template>
	<div class="pui-form pt-6">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mdhealt-form-header :modelPk="modelPk"></mdhealt-form-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="ml-2" dense>
				<v-col cols="3">
					<pui-select
						:id="`mdhealt-portcall`"
						attach="mdhealt-portcall"
						:label="$t('mdhealt.portcall')"
						toplabel
						clearable
						:disabled="!this.isCreatingElement || this.$route.params.parentpk"
						v-model="model"
						modelName="vlupportcalls"
						:modelFormMapping="{ portcallid: 'portcallid' }"
						:itemsToSelect="[{ portcallid: model.portcallid }]"
						:itemValue="['portcallid']"
						:itemText="(item) => `${item.visitid}`"
						:order="{ portcallid: 'desc' }"
						:fixedFilter="filterPortcalls"
					></pui-select>
				</v-col>
			</v-row>
			<pui-field-set :title="$t('mdhealt.ship.title')">
				<v-row dense>
					<v-col cols="3">
						<pui-text-field
							:label="$t('mdhealt.ship.shipname')"
							v-model="model.ship.shipname"
							maxlength="100"
							toplabel
							disabled
						></pui-text-field>
					</v-col>
					<v-col cols="3" v-if="this.isCreatingElement">
						<pui-text-field
							:label="$t('mdhealt.ship.mastername')"
							v-model="model.portcall.mastername"
							maxlength="100"
							@input="updateMastername($event)"
							toplabel
							:disabled="!this.isCreatingElement && model.docstatusid != 1"
						></pui-text-field>
					</v-col>
					<v-col cols="3" v-else>
						<pui-text-field
							:label="$t('mdhealt.ship.mastername')"
							v-model="model.mastername"
							maxlength="100"
							@input="updateMastername($event)"
							toplabel
							:disabled="model.docstatusid != 1"
						></pui-text-field>
					</v-col>
					<v-col cols="3">
						<!--<pui-text-field :label="$t('mdhealt.ship.flag')" v-model="model.ship.flag" maxlength="100" toplabel disabled></pui-text-field>-->
						<pui-select
							id="`mdhealt.ship.flag`"
							attach="mdhealt.ship.flag"
							:label="$t('mdhealt.ship.flag')"
							toplabel
							disabled
							clearable
							reactive
							v-model="model.ship"
							modelName="mcountries"
							:modelFormMapping="{ countrycode: 'countrycode' }"
							:itemsToSelect="[{ countrycode: this.model.ship.flag }]"
							:itemValue="['countrycode']"
							:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
							:order="{ countrydesc: 'asc' }"
						></pui-select>
					</v-col>
					<v-col cols="2">
						<pui-text-field
							:id="`mdhealt.ship.imo`"
							v-model="model.ship.imo"
							:label="$t('mdhealt.ship.imo')"
							toplabel
							disabled
						></pui-text-field>
					</v-col>
					<v-col cols="1">
						<pui-text-field
							:id="`mdhealt.ship.grosstonnage`"
							v-model="model.ship.grosstonnage"
							:label="$t('mdhealt.ship.grosstonnage')"
							toplabel
							disabled
							type="number"
							suffix="t"
						></pui-text-field>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('mdhealt.portofcall.fieldset')">
				<v-row dense>
					<v-col cols="3">
						<pui-select
							id="`mdhealt-portofarrival`"
							attach="mdhealt-portofarrival"
							:label="$t('mdhealt.portofcall.portofarrival')"
							toplabel
							v-model="model.portcall"
							modelName="mports"
							disabled
							clearable
							reactive
							:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
							:itemsToSelect="[{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
					<v-col cols="3">
						<pui-select
							id="`mdhealt-nextport`"
							attach="mdhealt-nextport"
							:label="$t('mdhealt.portofcall.nextport')"
							toplabel
							v-model="model.portcall"
							modelName="mports"
							disabled
							clearable
							reactive
							:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
							:itemsToSelect="[{ countrycode: this.model.portcall.nextcountry, portcode: this.model.portcall.nextport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
					<v-col cols="3">
						<pui-select
							id="`mdhealt-lastport`"
							attach="mdhealt-lastport"
							:label="$t('mdhealt.portofcall.lastport')"
							toplabel
							v-model="model.portcall"
							modelName="mports"
							disabled
							clearable
							reactive
							:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
							:itemsToSelect="[{ countrycode: this.model.portcall.previouscountry, portcode: this.model.portcall.previousport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
				</v-row>
			</pui-field-set>

			<v-row dense>
				<v-col cols="12">
					<nsw-editionable-table
						formComponent="previouscallsformsheet"
						:tableData="model.previouscalls"
						:itemsPerPage="10"
						:myColumns="myColumnspreviouscalls"
						:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
					>
					</nsw-editionable-table>
				</v-col>
			</v-row>

			<pui-field-set :title="$t('mdhealt.sanitationcert.title')">
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.validcertificate') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`validcertificate-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'validcertificate' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							required
							leftlabel
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.validcertificate }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
					<v-col cols="3" v-if="model.validcertificate == '1'">
						<pui-select
							id="`mdhealt-portofissue`"
							attach="mdhealt-portofissue"
							:label="$t('mdhealt.portofissue')"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							toplabel
							v-model="model"
							modelName="mports"
							required
							reactive
							clearable
							:modelFormMapping="{ countrycode: 'certissuecountry', portcode: 'certissueport' }"
							:itemsToSelect="[{ countrycode: model.certissuecountry, portcode: model.certissueport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
					<v-col cols="3" v-if="model.validcertificate == '1'">
						<pui-date-field
							:label="$t('mdhealt.issuedate')"
							v-model="model.certissuedate"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							toplabel
							:max="new Date()"
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.inspectionrequired') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`inspectionrequired-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'inspectionrequired' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: this.model.inspectionrequired }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('mdhealt.affectedarea.title')">
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.affectedareavisit') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`affectedareavisit-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'affectedareavisit' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.affectedareavisit }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
					<v-col cols="3" v-if="model.affectedareavisit == '1'">
						<pui-select
							id="`mdhealt-portofvisit`"
							attach="mdhealt-portofvisit"
							:label="$t('mdhealt.portofvisit')"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							toplabel
							v-model="model"
							modelName="mports"
							reactive
							clearable
							required
							:modelFormMapping="{ countrycode: 'affectedareacountry', portcode: 'affectedareaport' }"
							:itemsToSelect="[{ countrycode: model.affectedareacountry, portcode: model.affectedareaport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
					<v-col cols="3" v-if="model.affectedareavisit == '1'">
						<pui-date-field
							:label="$t('mdhealt.affectedareadate')"
							v-model="model.affectedareadate"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							toplabel
							:max="new Date()"
						></pui-date-field>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('mdhealt.questions.title')">
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.deadpersons') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`deadpersonsind-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'deadpersonsind' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.deadpersonsind }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense v-if="model.deadpersonsind == '1'">
					<v-col cols="12">
						<nsw-editionable-table
							formComponent="personslistformsheet"
							:tableData="model.deceaseds"
							:itemsPerPage="100"
							:myColumns="myColumnspersonslist"
							filterValue="1"
							filterColumn="isdeceased"
							:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
						>
						</nsw-editionable-table>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.infectiousdesease') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`infectiousdeseaseind-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'infectiousdeseaseind' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							required
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.infectiousdeseaseind }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense v-if="model.infectiousdeseaseind == '1'">
					<v-col cols="12">
						<nsw-editionable-table
							formComponent="personslistformsheet"
							:tableData="model.infectous"
							:itemsPerPage="100"
							:myColumns="myColumnspersonslist"
							filterValue="1"
							filterColumn="isinfectious"
							:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
						>
						</nsw-editionable-table>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.illpersonsgreater') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`illpersonsgreater-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'illpersonsgreater' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							required
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.illpersonsgreater }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.illpersonsnow') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`illpersonsnow-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'illpersonsnow' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							required
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.illpersonsnow }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense v-if="model.illpersonsnow == '1'">
					<v-col cols="12">
						<nsw-editionable-table
							formComponent="personslistformsheet"
							:tableData="model.ills"
							:itemsPerPage="100"
							:myColumns="myColumnspersonslist"
							filterValue="1"
							filterColumn="isill"
							:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
						>
						</nsw-editionable-table>
					</v-col>
				</v-row>
				<!--<v-row v-if="model.illpersonsnow == '1'" dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.practicionerconsulted') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`practicionerconsulted-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'practicionerconsulted' }"
							reactive
							required
							leftlabel
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.practicionerconsulted }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
					<v-col class="pt-6" cols="6">
						<label>{{ $t('mdhealt.practicionerconsulted2') }}</label>
					</v-col>
				</v-row>-->
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.infectionconditionind') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`infectionconditionind-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'infectionconditionind' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.infectionconditionind }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>

				<v-row dense v-if="model.infectionconditionind == '1'">
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.sanitarymeasureapplied') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`sanitarymeasureapplied-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'sanitarymeasureapplied' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.sanitarymeasureapplied }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
					<v-col cols="6" v-if="model.sanitarymeasureapplied == '1'">
						<v-row dense>
							<v-col cols="12">
								<pui-text-field
									:label="$t('mdhealt.sanitarymeasuredesc')"
									v-model="model.sanitarymeasuredesc"
									:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
									maxlength="100"
									toplabel
									required
								></pui-text-field
							></v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<pui-text-field
									:label="$t('mdhealt.sanitarymeasureplace')"
									v-model="model.sanitarymeasureplace"
									maxlength="100"
									toplabel
									required
									:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
								></pui-text-field
							></v-col>
							<v-col cols="6">
								<pui-date-field
									:label="$t('mdhealt.sanitarymeasuredate')"
									v-model="model.sanitarymeasuredate"
									:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
									toplabel
									required
									:max="new Date()"
								></pui-date-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.stowawayfound') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`stowawayfound-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'stowawayfound' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							clearable
							required
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.stowawayfound }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
					<v-col cols="4" v-if="model.stowawayfound == '1'">
						<pui-select
							id="`mdhealt-stowawayport`"
							attach="mdhealt-stowawayport"
							:label="$t('mdhealt.stowawayport')"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							toplabel
							v-model="model"
							modelName="mports"
							reactive
							clearable
							:modelFormMapping="{ countrycode: 'stowawaycountry', portcode: 'stowawayport' }"
							:itemsToSelect="[{ countrycode: model.stowawaycountry, portcode: model.stowawayport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pt-6" cols="4">
						<label>{{ $t('mdhealt.sickanimal') }}</label>
					</v-col>
					<v-col class="pt-6" cols="2">
						<pui-select
							:id="`sickanimal-mdhealt`"
							v-model="model"
							:modelFormMapping="{ valor: 'sickanimal' }"
							:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							reactive
							leftlabel
							required
							clearable
							modelName="vlupyesnoquestions"
							:itemsToSelect="[{ valor: model.sickanimal }]"
							:itemValue="['valor']"
							:itemText="(item) => `${item.opcion}`"
						></pui-select>
					</v-col>
				</v-row>
			</pui-field-set>

			<!-- Page Footer -->
			<pui-form-footer>
				<nsw-imo-form-footer-btns
					:formDisabled="(this.model.docstatusid == '3' && !isCreatingElement) || !havePermissions(['WRITE_MDHEALT'])"
					:saveDisabled="saving"
					:value="model"
					:saveAndUpdate="saveAndUpdate"
					:showNext="false"
					:showDraft="this.model.docstatusid != 2"
					:showSubmit="this.model.docstatusid != 2"
					:showNewVersion="false"
					modelName="mdhealt"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mdhealtActions from './MdhealtActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'mdhealt-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'mdhealt',
			actions: mdhealtActions.formactions,
			pkAttribute: 'mdhformid',
			myColumnspreviouscalls: ['port', 'previouscalldate'],
			myColumnspersonslist: [
				'familyname',
				'givennames',
				'birthdate',
				'gendercode',
				'nationalitydesc',
				'healthstatus',
				'casedisposal',
				'embarkation',
				'embarkationdate'
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'mdhealt');
			}
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
		},
		updateMastername(value) {
			this.model.mastername = value;
		}
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		}
	},
	created() {},
	watch: {
		'model.portcallid'(newVal) {
			if (newVal) {
				this.$puiRequests.getRequest(
					'/portcalls/get',
					{
						portcallid: newVal
					},
					(response) => {
						if (response.data) {
							this.model.ship = response.data.mship;
							this.model.portcall = response.data;
						}
					},
					() => {}
				);
			}
		}
	}
};
</script>
